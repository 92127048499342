<template>
  <div>
    <b-table striped
             hover
             small
             responsive
             class="asd"
             :items="team_laps"
             :fields="fields"
             >
    </b-table>

  </div>
</template>


<script>
export default {
  name: "Laps",
  data() {
    return {
      team_laps: [],
      fields: [],
    }
  },
  methods: {
  },
  mounted() {
    // eslint-disable-next-line no-undef
    axios
        .get('https://wyscig3h202110.xywek.pl/taki/laps_in_row.json', {
          dataType: 'json',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          mode: 'no-cors',
        })
        .then(response => {
          this.team_laps = response.data.Result;
          this.fields = response.data.Fields;
        })
  },
}
</script>

<style scoped>
.orange {
  background-color: orange;
}

.red {
  background-color: red;
}

.yellow {
  background-color: yellow;
}

.asd {
  border: none;
  max-height: 900px;
  font-size: x-small;
}
</style>