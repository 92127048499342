<template>
  <div>
    <b-table striped
             hover
             small
             responsive
             sticky-header
             class="asd"
             :items="team_stats"
             :fields="fields">
      <template #cell(lap_time)="row">
        {{ row }}
      </template>
    </b-table>
    <b-alert show>poz. 1 do 10 - ilość okrążeń na pozycji 1, 2 itd.</b-alert>
  </div>
</template>

<script>
export default {
  name: "GlobalStats",
  data() {
    return {
      team_stats: [],
      fields: [
        {label: 'zespół', key: 'team_name', sortable: true},
        {label: 'czas pitstopów', key: 'pit_loss', sortable: true},
        //{label: 'średnia czasów', key: 'avg_lap', sortable: true},
        {label: 'poz 1.', key: 'laps_on_pos1', sortable: true},
        {label: 'poz 2.', key: 'laps_on_pos2', sortable: true},
        {label: 'poz 3.', key: 'laps_on_pos3', sortable: true},
        {label: 'poz 4.', key: 'laps_on_pos4', sortable: true},
        {label: 'poz 5.', key: 'laps_on_pos5', sortable: true},
        {label: 'poz 6.', key: 'laps_on_pos6', sortable: true},
        {label: 'poz 7.', key: 'laps_on_pos7', sortable: true},
        {label: 'poz 8.', key: 'laps_on_pos8', sortable: true},
        {label: 'poz 9.', key: 'laps_on_pos9', sortable: true},
        {label: 'poz 10.', key: 'laps_on_pos10', sortable: true},
        {label: 'meta', key: 'finish_pos', sortable: true},
      ]
    }
  },
  methods: {},
  mounted() {
    // eslint-disable-next-line no-undef
    axios
        .get('https://wyscig3h202110.xywek.pl/taki/stats.json', {
          dataType: 'json',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          mode: 'no-cors',
        })
        .then(response => (this.team_stats = response.data.result))
  },
}
</script>

<style scoped>
.asd {
  border: none;
  max-height: 500px;
}
</style>